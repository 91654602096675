<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="models"
              backText="all models"
              :title="modelAbout.first_name+' '+modelAbout.last_name"
              sub-title="KNOW MORE ABOUT THIS MODEL"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>

    <ModelDetail
                @accept-field="acceptField"
                @accept-update="acceptUpdatedData"
                @reject-update="rejectUpdatedData"
                @append-data="checkUpdatedData"
                :isUpdated.sync="isUpdated"
                :fields-accepted="fieldsAccepted"
                :compare-data.sync="compareData"
                :compare-data-ready.sync="compareDataReady"
                :galleries="galleries"
                :model-about.sync="modelAbout"
                :model-about-object.sync="modelAboutObject"
    />
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
  </div>
</template>

<script>
import ModelRepository from "@/abstraction/repository/modelRepository";
import ModelDetail from "./ModelDetail.vue";
import {mapActions} from "vuex";
export default {
  components: {
    ModelDetail,
  },
  data() {
    return {
      isUpdated:false,
      fieldsAccepted:[],
      modelFieldAccepted:{},
      compareData:[],
      compareDataReady:false,
      modelAboutObject:{},
      modelAbout: {
        gender: {},
        model: {
          eye_color: {},
          hair_color: {},
          account_classification: {},
        },
        model_types: [],
        languagesWithLevel: [],
      },
      galleries: [],
      login: true,
    };
  },
  methods: {
    ...mapActions("model", ["acceptUpdateByAdmin","rejectUpdateByAdmin"]),
    acceptField(field){
      // if(this.fieldsAccepted.includes(field)){
      //   this.fieldsAccepted[field].pop()
      // }
      this.fieldsAccepted=field
    },

    rejectUpdatedData(){
      if(this.compareDataReady)
        this.rejectUpdateByAdmin(this.modelAbout)
    },
    acceptUpdatedData(){
      if(this.compareDataReady) {
        this.modelFieldAccepted.model={}
        this.modelFieldAccepted.id = this.modelAbout.id
        this.modelFieldAccepted.unique_id = this.modelAbout.unique_id

        this.fieldsAccepted.map(field=>{
          if(field.includes('model.')) {
            let field_ = field.replace('model.','');
            if(field_=='thumbnail'){
              this.modelFieldAccepted.file_batch_id = this.modelAbout.file_batch_id
            }
            this.modelFieldAccepted.model[field_] = this.modelAbout.model[field_]
          }
          else
            this.modelFieldAccepted[field] = this.modelAbout[field]
        })
        // if(this.modelFieldAccepted.model.length==0){
        //   delete this.modelFieldAccepted.model;
        // }
        // console.log(this.modelFieldAccepted)
        this.acceptUpdateByAdmin(this.modelFieldAccepted)
      }
    },
    checkUpdatedData(){
        this.isUpdated = !this.isUpdated;
        if (this.isUpdated) {
          this.modelAboutObject = this.modelAbout;
          if (this.modelAbout.model_updated_not_appended) {
            if (this.modelAbout.model_updated_not_appended.unique_id == null)
              this.modelAbout.model_updated_not_appended.unique_id = this.modelAbout.unique_id
          }
          this.modelAbout = this.modelAbout.model_updated_not_appended;
          if (this.compareData.length === 0) {
            for (const item in this.modelAbout) {
              if (typeof this.modelAboutObject[item] != 'undefined') {
                //if level 1 compare
                if (this.modelAboutObject[item] != this.modelAbout[item]) {
                  if ('model_types' === item || 'languagesWithLevel' === item) {
                    //    for(let model_type in this.modelAbout.model_types){
                    //      if(this.modelAbout.model_types[model_type].name !==null) {
                    //        if (this.modelAbout.model_types[model_type].name !== this.modelAboutObject.model_types[model_type].name)
                    //          this.compareData.push(item)
                    //      }
                    //    }
                  } else {
                    this.compareData.push(item)
                  }
                }
              }
            }

            if (this.modelAbout.model_types.length > 0) {
              //if level 2 compare
              if (this.modelAboutObject.model_types.length != this.modelAbout.model_types.length) {
                this.compareData.push('model_types')
              } else {
                for (const key in this.modelAbout.model_types) {
                  if (typeof this.modelAboutObject.model_types[key] !== 'undefined') {
                    if (this.modelAboutObject.model_types[key].id != this.modelAbout.model_types[key].id) {
                      this.compareData.push('model_types')
                    }
                  }
                }
              }
            }
            if (this.modelAbout.languagesWithLevel.length > 0) {
              //if level 2 compare
              if (this.modelAboutObject.languagesWithLevel.length != this.modelAbout.languagesWithLevel.length) {
                this.compareData.push('languagesWithLevel')
              } else {
                for (const key in this.modelAbout.languagesWithLevel) {
                  if (typeof this.modelAboutObject.languagesWithLevel[key] !== 'undefined') {
                    if (this.modelAboutObject.languagesWithLevel[key].id != this.modelAbout.languagesWithLevel[key].id) {
                      this.compareData.push('languagesWithLevel')
                    }
                    if (this.modelAboutObject.languagesWithLevel[key].level_id != this.modelAbout.languagesWithLevel[key].level_id) {
                      this.compareData.push('languagesWithLevel')
                    }
                  }
                }
              }
            }
            if (this.compareData.includes('model')) {
              //if level 2 compare
              for (const item in this.modelAbout.model) {
                if (typeof this.modelAboutObject.model[item] !== 'undefined') {
                  if (this.modelAboutObject.model[item] != this.modelAbout.model[item]) {
                    // console.log(item=='model_types')
                    this.compareData.push('model.' + item)
                  }
                }
              }
            }
          }
          this.compareDataReady = true;
        } else {
          this.compareDataReady = false;
          this.modelAbout = this.modelAboutObject
        }

    },

    async loadModelAbout() {
      let repository = new ModelRepository();
      this.modelAbout = await repository.show(this.$route.params.id);
      this.galleries = this.modelAbout.model.galleries ? this.modelAbout.model.galleries.map((x) => {
        return {
          thumb: x.thumbnail,
          src: x.image,
        };
      }) : [];
    },
  },
  created() {
    this.loadModelAbout();
  },
};
</script>

<style>
.v-input.theme--light.v-input--selection-controls.v-input--checkbox {
  top: -60px;
  position: relative;
  left: -30px;
}
</style>
